<template>
  <div class="sign-in">
    <el-form label-position="top">
      <el-form-item label="Login">
        <el-input
          v-model="authData.username"
          @keydown.enter="login"
        />
      </el-form-item>
      <el-form-item label="Password">
        <el-input
          v-model="authData.password"
          show-password
          @keydown.enter="login"
        />
      </el-form-item>

      <el-button
        type="primary"
        plain
        :disabled="buttonDisabled"
        :loading="loading"
        @click="login"
      >
        Sign
      </el-button>
    </el-form>
  </div>
</template>

<script>
import { ElNotification } from 'element-plus';
import MainService from '../services/main-service';
import tokenService from '../api/jwt.service';

export default {
  name: 'SignIn',
  props: {
    auth: { type: Boolean, default: true },
  },
  data: () => ({
    loading: false,
    authData: {
      username: 'admin',
      password: 'password',
    },
  }),
  computed: {
    buttonDisabled() {
      return !(this.authData.username && this.authData.password);
    },
  },
  methods: {
    login() {
      this.loading = true;

      MainService.login(this.authData)
        .then((response) => {
          if (response?.data) {
            tokenService.login(response.data);
            this.$emit('update:auth', true);
          } else {
            throw new Error();
          }
        })
        .catch(() => {
          ElNotification({
            title: 'Error sing in',
            type: 'error',
            message: 'Incorrect login or password',
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style>

  .sign-in {
    display: flex;
    min-height: 95vh;
    min-width: 95vw;
  }

  .el-form {
    margin: auto;
    min-width: 50%;
  }

  .el-form-item__label {
    padding-bottom: 0 !important;
  }

</style>
