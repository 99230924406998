<template>
  <el-aside :width="menu ? '256px' : '64px'">
    <el-menu
      v-if="currentRoute"
      class="el-menu-vertical-demo"
      router
    >
      <el-menu-item
        index="1100"
        :route="{ name: 'home' }"
      >
        <span>Главная</span>
      </el-menu-item>
      <el-menu-item
        index="1099"
        :class="{ 'is-active': isCurrentRoute('channels') }"
        :route="{ name: 'channels' }"
      >
        <span>Каналы telegram</span>
      </el-menu-item>
      <el-sub-menu index="100">
        <template #title>
          <span>Публикации</span>
        </template>
        <el-menu-item-group>
          <el-menu-item
            v-for="(item, idx) in navItems1"
            :key="idx"
            :index="(idx + 1).toString()"
            :class="{ 'is-active': isCurrentRoute(item.routeName) }"
            :route="{ name: item.routeName }"
          >
            <span v-show="menu">{{ item.name }}</span>
          </el-menu-item>
        </el-menu-item-group>
      </el-sub-menu>
      <el-sub-menu index="200">
        <template #title>
          <span>Статьи</span>
        </template>
        <el-menu-item-group>
          <el-menu-item
            v-for="(item, idx) in navItems2"
            :key="idx"
            :index="(idx + 100 + 1).toString()"
            :class="{ 'is-active': isCurrentRoute(item.routeName) }"
            :route="{ name: item.routeName }"
          >
            <span v-show="menu">{{ item.name }}</span>
          </el-menu-item>
        </el-menu-item-group>
      </el-sub-menu>
      <el-sub-menu index="300">
        <template #title>
          <span>Сервер</span>
        </template>
        <el-menu-item-group>
          <el-menu-item
            v-for="(item, idx) in navItems3"
            :key="idx"
            :index="(idx + 200 + 1).toString()"
            :class="{ 'is-active': isCurrentRoute(item.routeName) }"
            :route="{ name: item.routeName }"
          >
            <span v-show="menu">{{ item.name }}</span>
          </el-menu-item>
        </el-menu-item-group>
      </el-sub-menu>
      <el-menu-item
        index="998"
        :route="{ name: 'telegram-users' }"
        :class="{ 'is-active': isCurrentRoute('telegram-users') }"
      >
        <span>Пользователи</span>
      </el-menu-item>
      <el-menu-item
        index="997"
        :route="{ name: 'orders' }"
        :class="{ 'is-active': isCurrentRoute('orders') }"
      >
        <span>Заказы</span>
      </el-menu-item>
      <el-menu-item
        index="996"
        :route="{ name: 'products' }"
        :class="{ 'is-active': isCurrentRoute('products') }"
      >
        <span>Продукты</span>
      </el-menu-item>
      <el-menu-item
        index="999"
        :route="{ name: 'system-settings' }"
        :class="{ 'is-active': isCurrentRoute('system-settings') }"
      >
        <span>Системные настройки</span>
      </el-menu-item>
      <el-menu-item
        index="1200"
        @click="logout"
      >
        <span>Выйти</span>
      </el-menu-item>
    </el-menu>
  </el-aside>
</template>

<script>
import tokenService from '../api/jwt.service';

export default {
  name: 'TheNavbar',
  props: {
    auth: { type: Boolean, default: true },
    menu: { type: Boolean, default: true },
  },
  data: () => ({
    navItems1: [
      { name: 'Публикации telegram', routeName: 'telegram-publication', icon: 'el-icon-lollipop' },
      { name: 'Переводы telegram', routeName: 'telegram-publication-translates', icon: 'el-icon-lollipop' },
      { name: 'Дубликаты публикаций', routeName: 'telegram-publication-duplicates', icon: 'el-icon-lollipop' },
      { name: 'Выделенные события', routeName: 'telegram-events', icon: 'el-icon-lollipop' },
      { name: 'Актуальные события', routeName: 'telegram-actual-events', icon: 'el-icon-lollipop' },
      { name: 'Timeline', routeName: 'telegram-event-timelines', icon: 'el-icon-lollipop' },
    ],
    navItems2: [
      { name: 'GPT prompt', routeName: 'gpt-prompts', icon: 'el-icon-lollipop' },
      { name: 'Очередь статей', routeName: 'article-queue', icon: 'el-icon-lollipop' },
      { name: 'Статьи', routeName: 'articles', icon: 'el-icon-lollipop' },
      { name: 'Модерация статей', routeName: 'articles-moderation', icon: 'el-icon-lollipop' },
      { name: 'Мнения', routeName: 'opinions', icon: 'el-icon-lollipop' },
      { name: 'Вопросы', routeName: 'question-answer', icon: 'el-icon-lollipop' },
    ],
    navItems3: [
      { name: 'GPT логи', routeName: 'gpt-logs', icon: 'el-icon-lollipop' },
      { name: 'GPT статистика', routeName: 'gpt-statistic', icon: 'el-icon-lollipop' },
    ],
  }),
  computed: {
    currentRoute() {
      return this.$route.name;
    },
  },
  methods: {
    logout() {
      tokenService.logout();
      this.$emit('update:auth', false);
    },
    isCurrentRoute(routeName) {
      return this.currentRoute === routeName;
    },
  },
};
</script>

<style scoped>

  .el-menu {
    min-height: 100%;
  }

  a {
    text-decoration: none;
  }

</style>
