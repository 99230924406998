import axios from 'axios';
import { BASE_URL } from '@/constants';
import tokenService from './jwt.service';


export class ApiService {
  constructor(baseURL) {
    this.cacheStorage = {};
    this.tokenInterceptor = null;
    this.refreshTokenInterceptor = null;

    this.axios = axios.create({
      baseURL,
    });

    if (tokenService.getAuthStatus) {
      this.mountAuthInterceptors();
    }
  }

  /*
   * Достает из кеша response по url,
   * если такой не найден, делает get запрос и добавляет в кеш
   *
   * @return Promise<response>
   */
  getCached(resource, params) {
    const value = this.cacheStorage[resource];
    if (value) return value;

    this.cacheStorage[resource] = this.get(resource, params);
    return this.cacheStorage[resource];
  }

  get(resource, params) {
    return this.axios.get(`${resource}`, { params });
  }

  post(resource, params, headers) {
    return this.axios.post(`${resource}`, params, headers);
  }

  put(resource, params, headers) {
    return this.axios.put(`${resource}`, params, headers);
  }

  patch(resource, params, headers) {
    return this.axios.patch(`${resource}`, params, headers);
  }

  delete(resource, params) {
    return this.axios.delete(`${resource}`, { data: params });
  }

  customRequest(data) {
    return this.axios(data);
  }

  mountAuthInterceptors() {
    this.tokenInterceptor = this.axios.interceptors.request.use((config) => {
      const token = tokenService.getToken();
      config.headers.Authorization = token ? `Bearer ${token}` : '';
      return config;
    });

    this.refreshTokenInterceptor = this.axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        if (error.request.status === 401) {
          try {
            if (error.config.data && typeof error.config.data === 'string') {
              error.config.data = JSON.parse(error.config.data);
            } else {
              // TODO get refresh token
              throw error;
            }
            return this.customRequest({
              method: error.config.method,
              url: error.config.url,
              data: error.config.data,
              params: error.config.params,
            });
          } catch (e) {
            tokenService.logout();
            throw error;
          }
        }

        // If error was not 401 just reject as is
        throw error;
      },
    );
  }

  unmountAuthInterceptors() {
    // Eject the interceptors
    this.axios.interceptors.request.eject(this.tokenInterceptor);
    this.axios.interceptors.response.eject(this.refreshTokenInterceptor);
  }
}

export const baseService = new ApiService(BASE_URL);
export const baseServiceV1 = new ApiService(`${BASE_URL}/`);

export default { baseService, baseServiceV1 };
