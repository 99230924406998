import { createApp } from 'vue';

// ElementPlus
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import RHandler from '@/plugins/RHandler';

import router from './router';
import App from './App.vue';

createApp(App)
  .use(router)
  .use(ElementPlus)
  .use(RHandler)
  .mount('#app');
