<template>
  <!-- eslint-disable vue/no-v-model-argument -->
  <el-container>
    <template v-if="authentication">
      <the-navbar
        v-model:menu="menu"
        v-model:auth="authentication"
      />
      <el-container direction="vertical">
        <the-page-header v-model:menu="menu" />
        <el-main>
          <router-view v-slot="{ Component }">
            <keep-alive>
              <component :is="Component" />
            </keep-alive>
          </router-view>
        </el-main>
      </el-container>
      <el-backtop />
    </template>

    <sign-in
      v-else
      v-model:auth="authentication"
    />
  </el-container>
</template>

<script>
import tokenService from './api/jwt.service';

import TheNavbar from './components/TheNavbar';
import ThePageHeader from './components/ThePageHeader';
import SignIn from './views/SignIn';

export default {
  components: { SignIn, ThePageHeader, TheNavbar },
  data: () => ({
    authentication: Boolean(tokenService.getAuthStatus()),
    menu: true,
  }),
};
</script>

<style src="./styles/index.css"></style>
