const ID_TOKEN_KEY = 'schro_id_token';
const REFRESH_TOKEN_KEY = 'schro_refresh_token';
const AUTH_STATUS_KEY = 'schro_auth_status';

export default {
  getToken() {
    return window.localStorage.getItem(ID_TOKEN_KEY);
  },

  saveToken(token) {
    window.localStorage.setItem(ID_TOKEN_KEY, token);
  },

  destroyToken() {
    window.localStorage.removeItem(ID_TOKEN_KEY);
  },

  getAuthStatus() {
    return window.localStorage.getItem(AUTH_STATUS_KEY);
  },

  setAuthStatus(status) {
    window.localStorage.setItem(AUTH_STATUS_KEY, status);
  },

  saveRefreshToken(refreshToken) {
    localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
  },

  destroyRefreshToken() {
    localStorage.removeItem(REFRESH_TOKEN_KEY);
  },

  login(data) {
    this.saveToken(data.access_token);
    this.saveRefreshToken(data.refresh_token);
    this.setAuthStatus(1);
  },

  logout() {
    this.saveToken('');
    this.saveRefreshToken('');
    this.setAuthStatus('');
    document.location.replace('/');
  },

};
