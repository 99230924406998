import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('./views/HomePage'),
  },
  {
    path: '/users',
    name: 'users',
    component: () => import('./views/Users/ListUsers'),
  },
  {
    path: '/channels',
    name: 'channels',
    component: () => import('./views/Channels/ChannelsList'),
  },
  {
    path: '/channels/:id',
    name: 'channelSingle',
    component: () => import('./views/Channels/ChannelsList'),
  },
  {
    path: '/telegram-publications',
    name: 'telegram-publication',
    component: () => import('./views/TelegramPublication/TelegramPublicationList'),
  },
  {
    path: '/telegram-publications/:id',
    name: 'telegramPublicationSingle',
    component: () => import('./views/TelegramPublication/TelegramPublicationView'),
  },
  {
    path: '/telegram-publications-translates',
    name: 'telegram-publication-translates',
    component: () => import('./views/TelegramPublicationTranslates/TelegramPublicationTranslatesList'),
  },
  {
    path: '/telegram-publications-translates/:id',
    name: 'telegramPublicationTranslatesSingle',
    component: () => import('./views/TelegramPublicationTranslates/TelegramPublicationTranslatesView'),
  },
  {
    path: '/telegram-publication-duplicates',
    name: 'telegram-publication-duplicates',
    component: () => import('./views/TelegramPublicationDuplicates/TelegramPublicationDuplicatesList'),
  },
  {
    path: '/telegram-publication-duplicates/:id',
    name: 'telegramPublicationDuplicatesSingle',
    component: () => import('./views/TelegramPublicationDuplicates/TelegramPublicationDuplicatesView'),
  },
  {
    path: '/gpt-prompts',
    name: 'gpt-prompts',
    component: () => import('./views/GptPrompt/GptPromptList'),
  },
  {
    path: '/gpt-prompts/:id',
    name: 'gptPromptSingle',
    component: () => import('./views/GptPrompt/GptPromptView'),
  },
  {
    path: '/article-queue',
    name: 'article-queue',
    component: () => import('./views/ArticlesQueue/ArticlesQueueList'),
  },
  {
    path: '/article-queue/:id',
    name: 'articleQueueSingle',
    component: () => import('./views/ArticlesQueue/ArticlesQueueView'),
  },
  {
    path: '/articles',
    name: 'articles',
    component: () => import('./views/Articles/ArticlesList'),
  },
  {
    path: '/articles/:id',
    name: 'articleSingle',
    component: () => import('./views/Articles/ArticlesView'),
  },
  {
    path: '/articles-moderation',
    name: 'articles-moderation',
    component: () => import('./views/ArticlesModeration/ArticlesList'),
  },
  {
    path: '/articles-moderation/:id',
    name: 'articleModerationSingle',
    component: () => import('./views/ArticlesModeration/ArticlesView'),
  },
  {
    path: '/telegram-events',
    name: 'telegram-events',
    component: () => import('./views/TelegramEvent/TelegramEventList'),
  },
  {
    path: '/telegram-event-timelines',
    name: 'telegram-event-timelines',
    component: () => import('./views/TelegramEventTimeline/TelegramEventTimelineList'),
  },
  {
    path: '/telegram-event-timelines/:id',
    name: 'telegramEventTimelinesSingle',
    component: () => import('./views/TelegramEventTimeline/TelegramEventTimelineView'),
  },
  {
    path: '/telegram-actual-events',
    name: 'telegram-actual-events',
    component: () => import('./views/TelegramEvent/TelegramEventActualList'),
  },
  {
    path: '/telegram-events/:id',
    name: 'telegramEventSingle',
    component: () => import('./views/TelegramEvent/TelegramEventView'),
  },
  {
    path: '/telegram-events/create-article/:id',
    name: 'telegramEventArticle',
    component: () => import('./views/TelegramEvent/TelegramEventCreateArticle'),
  },
  {
    path: '/telegram-event-timeline/:id',
    name: 'telegramEventTimeline',
    component: () => import('./views/TelegramEvent/TelegramEventTimeline'),
  },
  {
    path: '/users/:id',
    name: 'userSingle',
    component: () => import('./views/Users/UserView'),
  },
  {
    path: '/system-settings',
    name: 'system-settings',
    component: () => import('./views/SystemSettings/SystemSettingsView'),
  },
  {
    path: '/gpt-logs',
    name: 'gpt-logs',
    component: () => import('./views/Log/LogsList'),
  },
  {
    path: '/gpt-logs/:id',
    name: 'gptLogSingle',
    component: () => import('./views/Log/LogsView'),
  },
  {
    path: '/opinions',
    name: 'opinions',
    component: () => import('./views/Opinion/OpinionList'),
  },
  {
    path: '/gpt-statistic',
    name: 'gpt-statistic',
    component: () => import('./views/Statistic/StatisticView'),
  },
  {
    path: '/telegram-users',
    name: 'telegram-users',
    component: () => import('./views/TelegramUsers/TelegramUsersList'),
  },
  {
    path: '/telegram-users/:id',
    name: 'telegramUsersSingle',
    component: () => import('./views/TelegramUsers/TelegramUserView'),
  },
  {
    path: '/question-answer',
    name: 'question-answer',
    component: () => import('./views/QuestionAnswer/QuestionAnswerList'),
  },
  {
    path: '/question-answer/:id',
    name: 'questionAnswerSingle',
    component: () => import('./views/QuestionAnswer/QuestionAnswerView'),
  },
  {
    path: '/orders',
    name: 'orders',
    component: () => import('./views/Orders/OrdersList'),
  },
  {
    path: '/orders/:id',
    name: 'orderSingle',
    component: () => import('./views/Orders/OrderView'),
  },
  {
    path: '/products',
    name: 'products',
    component: () => import('./views/Products/ProductsList'),
  },
  {
    path: '/products/:id',
    name: 'productSingle',
    component: () => import('./views/Products/ProductView'),
  },
  {
    path: '/404',
    name: 'not-found',
    component: () => import('./views/NotFound'),
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: 'not-found' },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
