import CRUDFactory from '../api/CRUDFactory';

export const CRUDUsers = new CRUDFactory('users');
export const CRUDTelegramPublication = new CRUDFactory('telegram-publications');
export const CRUDTelegramPublicationTranslates = new CRUDFactory('telegram-publications-translates');
export const CRUDTelegramEvents = new CRUDFactory('telegram-events');
export const CRUDTelegramActualEvents = new CRUDFactory('telegram-events/actual');
export const CRUDSystemSettings = new CRUDFactory('system-settings');
export const CRUDGptPrompts = new CRUDFactory('gpt-prompts');
export const CRUDArticleQueue = new CRUDFactory('article-queue');
export const CRUDArticle = new CRUDFactory('articles');
export const CRUDChannels = new CRUDFactory('channels');
export const CRUDLogs = new CRUDFactory('logs');
export const CRUDStatistic = new CRUDFactory('statistic');
export const CRUDPublicationDuplicates = new CRUDFactory('publication-duplicates');
export const CRUDEventTimeline = new CRUDFactory('telegram-event-timelines');
export const CRUDOpinion = new CRUDFactory('opinions');
export const CRUDTelegramUsers = new CRUDFactory('telegram-users');
export const CRUDQuestionAnswer = new CRUDFactory('question-answers');
export const CRUDOrders = new CRUDFactory('orders');
export const CRUDProducts = new CRUDFactory('products');
export const CRUDProcess = new CRUDFactory('process');
