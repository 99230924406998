import { ElNotification } from 'element-plus';
import router from '@/router';

const RHandler = {};

RHandler.install = (app) => {
  function setValidationErrors(errors = {}) {
    Object.entries(errors).forEach(([, value], i) => {
      value = Array.isArray(value) ? value.join(', ') : value;
      ElNotification({
        title: 'Ошибка',
        type: 'error',
        duration: 4000 + 500 * i,
        message: value,
      });
    });
  }

  app.config.globalProperties.$rHandler = (promise, onSuccess = () => {}, onReject = () => {}) => (
    promise
      .then((response) => {
        if (!response) return onReject();
        return onSuccess(response);
      })
      .catch((error) => {
        if (error?.response?.status === 404) {
          router.push({ name: 'not-found' });
        }
        if (error?.response?.status === 500) {
          ElNotification({
            title: 'Ошибка',
            type: 'error',
            duration: 4500,
            message: error.message,
          });
        }
        const rejectResponse = onReject(error);
        if (rejectResponse === undefined) {
          if (error.response?.data?.result_code === 'VAL01') {
            setValidationErrors(error.response.data?.validation);
          }
        }
        return rejectResponse;
      })
  );
};

export default RHandler;
