<template>
  <el-header height="50px">
    <span>GPT news</span>
    <el-switch
      v-if="loaded"
      v-model="serverEnabled"
      class="ml-2"
      style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949"
    />
  </el-header>
</template>

<script>
import { baseServiceV1 as baseService } from '@/api/api.service';
import { CRUDSystemSettings } from '@/services/crud-service';

export default {
  name: 'ThePageHeader',
  props: {
    menu: { type: Boolean, default: true },
  },
  data: () => ({
    loaded: false,
    serverEnabled: false,
  }),
  watch: {
    serverEnabled() {
      this.toggleEnabled();
    },
  },
  mounted() {
    this.getToggle();
  },
  methods: {
    getToggle() {
      const params = {};
      this.$rHandler(
        CRUDSystemSettings.getAll(params),
        ({ data }) => {
          this.serverEnabled = data.data.options.run_server;
        },
      ).finally(() => { this.loaded = true; });
    },
    toggleEnabled() {
      this.loading = true;
      const data = {
        run_server: this.serverEnabled,
      };

      baseService.put('system-settings', data);
    },
  },
};
</script>

<style scoped>

  .el-header {
    display: flex;
    align-items: center;
  }

</style>

