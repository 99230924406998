import { baseServiceV1 as baseService } from './api.service';

export default class {
  constructor(url) {
    this.url = url;
  }

  getAll(params) {
    return baseService.get(this.url, params);
  }

  get(id, params) {
    return baseService.get(`${this.url}/${id}`, params);
  }

  create(params) {
    return baseService.post(this.url, params);
  }

  update(id, params) {
    return baseService.put(`${this.url}/${id}`, params);
  }

  delete(id) {
    return baseService.delete(`${this.url}/${id}`);
  }

  customPost(url, params) {
    return baseService.post(`${this.url}/${url}`, params);
  }
}
